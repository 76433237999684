// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --app-color-primary: #000000ff;
  --app-color-primary-hover: #030c13;
  --app-color-primary-light: #8b8b8b;
  --app-color-primary-light-hover: #6f6e6e;
  --app-color-primary-stroke: #f5127b;
  --app-color-primary-stroke-historic: #f5127b;
  --app-color-border-primary: #f5127b;
  --app-color-secondary: #f5127b;
  --app-color-secondary-hover: #d50967;
  --app-color-background: #000;
  --app-color-background-2: #ffffff00;
  --app-color-confirm: #f5127b;
  --app-color-confirm-hover: #d50967;

  --rounded-default: 0;

  --button-color-primary: #f5127b;
  --button-color-success: #f5127b;
  --button-color-primary-hover: #d50967;
  --button-color-secondary-hover: #f5127b;
  --button-color-tertiary-hover: #ffffff;
  --button-color-success-hover: #d50967;
  --button-color-primary-border: #f5127b;
  --button-color-secondary-border: #000000ff;
  --button-color-primary-text: #000000ff;
  --button-color-primary-text-hover: #ffffff;
  --button-color-secondary-text: #000000ff;
  --button-color-secondary-text-hover: #000000ff;
  --button-color-tertiary-text: #000000ff;
  --button-color-tertiary-text-hover: #000000ff;
}

.auth-background {
  background: var(--app-color-primary);
}